
import { defineComponent } from 'vue';


/*
 error = 'ups'...
 payload: [{}, {}, {}]


class TResp<T, E> {

	error?: string;
	payload?: E;
	data?: T;

	constructor(data: T, error?: string, payload?: E) {
		this.data = data;
		this.error = error;
		this.payload  = payload;
	}

}

interface HZ {
	A: boolean;
	B: string;
}
interface HZError {
	A: string;
	B: string;
}
function fa(): TResp<HZ[], HZError> {
	const hz: HZ[]= [
		{
			A: true,
			B: 'data'
		}
	];
	return new TResp<HZ[], HZError>(hz);
}

const x = fa();

if (x.data) {
	console.log(x.data[0].A);
}
*/
export default defineComponent({
	name: 'Login',
	data() {
		return {
			changed: false,
			password: '',
			password1: '',
			errors: '' as string | undefined
		};
	},
	methods: {
		resetPassword() {
			this.errors = undefined;
			if (!this.password?.trim()) {
				this.errors = 'Please, enter the new password';
			} else if (!this.password1?.trim()) {
				this.errors = 'Please, enter the confirmation';
			} else if (this.password != this.password1) {
				this.errors = 'Password and confirmation password must be the same';
			} else if (this.password.length < 5) {
				this.errors = 'Password must be at least 5 characters';
			}

			const key = this.$route.params.key as string;
			if (!key) {
				this.errors = 'wrong key';
			}
			if (this.errors) {
				return;
			}

			this.$api.users.passwordReset(key, this.password).
				then((msg: string) => {
					if (msg == 'ok') {
						this.changed = true;
					}else {
						this.errors = msg;
					}
				});
		}
	}
});
